import { Component, Input, OnInit } from '@angular/core';
import { Faq } from 'src/app/core/models/data.model';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() type: string = "";
  @Input() hasTitle: boolean = true;
  @Input() showAll: boolean = false;
  public faqs: Faq[] = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getFaq().subscribe((faq: any) => {
      if (!this.showAll) {
        this.faqs = faq[this.type];
      } else {
        let sequence: string = '01';
        Object.keys(faq).forEach(f => {
          Object.values(faq[f]).forEach((v: any) => {
            v.sequence = sequence;
            let seqNum = parseInt(sequence);
            seqNum++;
            sequence = seqNum.toString().padStart(2, '0');
            this.faqs.push(v);
          });
        });
      }
    });
  }
}
