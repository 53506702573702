<div class="zaper contact-section" [ngClass]="{'home-contact':isHome}">
  <h1><span>Let's talk,</span> <br>We would love to hear from you!</h1>
  <form [formGroup]="contactForm" (ngSubmit)="submit()" class="contact-form">
    <div class="input-group">
      <label for="name">
        <i class="fas fa-user"></i>
        <input type="text" id="name" placeholder="Your Name" formControlName="name">
      </label>
      <div *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.invalid">
        <small *ngIf="contactForm.get('name')?.errors?.['required']" class="text-danger">
          Name is required.
        </small>
      </div>

    </div>
    <div class="input-group">
      <label for="email">
        <i class="fas fa-envelope"></i>
        <input type="email" id="email" placeholder="Email" formControlName="email">
      </label>
      <div *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.invalid">
        <small *ngIf="contactForm.get('email')?.errors?.['required']" class="text-danger">
          Email is required.
        </small>
        <small *ngIf="contactForm.get('email')?.errors?.['email']" class="text-danger">
          Please enter a valid email address.
        </small>
      </div>
    </div>
    <div class="input-group">
      <label for="phone">
        <i class="fas fa-phone"></i>
        <input type="tel" id="phone" placeholder="Phone Number" formControlName="phone_number">
      </label>
      <div *ngIf="contactForm.get('phone_number')?.touched && contactForm.get('phone_number')?.invalid">
        <small *ngIf="contactForm.get('phone_number')?.errors?.['required']" class="text-danger">
          Phone number is required.
        </small>
      </div>
    </div>
    <button type="submit" class="zaper-btn" [disabled]="isSent">SUBMIT</button>
  </form>
</div>