import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./views/security/security.module').then(m => m.SecurityModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./views/careers/careers.module').then(m => m.CareersModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: '',
    loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled',
    // anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
