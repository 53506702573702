import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Banner, ContactData, Faq, Industry, Job, Product, Team, Workflow } from '../models/data.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient) { }
  public getBanners(): Observable<Banner[]> {
    return this.httpClient.get<Banner[]>(`assets/data/banners.json`);
  }

  public getIndustries(): Observable<Industry[]> {
    return this.httpClient.get<Industry[]>(`assets/data/industries.json`);
  }

  public getTeams(): Observable<Team[]> {
    return this.httpClient.get<Team[]>(`assets/data/teams.json`);
  }

  public getJobs(): Observable<Job[]> {
    return this.httpClient.get<Job[]>(`assets/data/jobs.json`);
  }

  public getJobByTitle(key: string): Observable<Job | undefined> {
    return this.httpClient.get<Job[]>('assets/data/jobs.json').pipe(
      map(jobs => jobs.find(job => job.key === key))
    );
  }

  public getContacts(): Observable<ContactData[]> {
    return this.httpClient.get<ContactData[]>(`assets/data/contacts.json`);
  }

  public getFaq(): Observable<any> {
    return this.httpClient.get<any>(`assets/data/faq.json`);
  }

}
