import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from 'src/app/core/services/domain.service';

@Component({
  selector: 'app-zaper-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() isHome: boolean = false;

  public contactForm!: FormGroup
  public isSent: boolean = false;
  constructor(private fb: FormBuilder,
    private domainService: DomainService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      phone_number: this.fb.control('', [Validators.required])
    })
  }

  public submit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.isSent = true;
      this.domainService.sendContactDetails(this.contactForm.value).subscribe({
        next: (response) => {
          this.isSent = false;
          if (response) {
            this.contactForm.reset();
            this.toastr.success(response?.message, 'Thank you for reaching out!', { positionClass: 'toast-bottom-right' });
            setTimeout(() => {
              this.router.navigate(['/']);
              window.scrollTo(0, 0);
            }, 3000);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.isSent = false;
          this.toastr.error(error?.error || error?.message);
          this.contactForm.reset();
        }
      });
    }
  }

}
