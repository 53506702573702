import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '../models/domain.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private httpClient: HttpClient, private toastrService: ToastrService) { }

  public sendContactDetails(contact: Contact): Observable<any> {
    return this.httpClient.post<any>(`https://attendance-app-o4mymrguzq-uc.a.run.app/contact_us`, contact);
  }

  copyToClipboard(email: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(
        () => {
          this.toastrService.success('Email copied to clipboard!', 'Thank you for reaching out!', { positionClass: 'toast-top-right' });
        },
        (err) => {
          console.error('Failed to copy: ', err);
        }
      );
    } else {
      this.fallbackCopyText(email);
    }
  }

  private fallbackCopyText(email: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = email;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      this.toastrService.success('Email copied to clipboard!', 'Thank you for reaching out!', { positionClass: 'toast-top-right' });

    } catch (err) {
      console.error('Failed to copy text: ', err);
    } finally {
      document.body.removeChild(textarea);
    }
  }

  public getSummaryFromText(file: FormData): Observable<any> {
    return this.httpClient.post<any>(`https://attendance-app-o4mymrguzq-uc.a.run.app/summarize_audio`, file);
  }
}
