<div class="summary-container">
  <!-- <img class="close-btn" src="assets/images/icons/close.png" alt="close.png" (click)="activeModal.close('Click')"> -->
  <div class="heading">
    <img src="assets/images/icons/star-light.png" alt="star.png">
    <h5>Summarized Points</h5>
  </div>
  <!-- <p class="description">
        10 points summarized from your voice record
    </p> -->
  <div class="scroll-container">
    <div class="scroll-area" id="scrollArea">
      <img *ngIf="isLoading" class="loading" src="assets/gifs/spinner.gif" alt="loader.gif">
      <div class="cards-container" id="cardsContainer">
        <div class="card" *ngFor="let task of tasks">
          <div class="text">{{task.description}}</div>
          <div class="status" [ngClass]="{'success':task.flag==='positive' || task.flag==='todo','issue':task.flag==='negative'}">
            {{task.flag==='positive'|| task.flag==='todo'?'Progress':'Issue'}}
            <img [ngClass]="{'insight':task.flag==='positive'|| task.flag==='todo','warning':task.flag==='negative'}"
              [src]="task.flag==='positive'|| task.flag==='todo'?'assets/images/icons/insight-dark.png':'assets/images/icons/warning-dark.png'"
              alt="icon.png">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="action-buttons"*ngIf="!isLoading">
    <button class="retry" type="button" (click)="onAction(true)">RETRY</button>
    <button class="close" type="button" (click)="onAction(false)">CLOSE</button>
  </div>
</div>