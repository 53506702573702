import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], keys: string, term: string): any[] {
    if (!term) return items;
    const regex = new RegExp(term, 'gi');
    return items.filter(item =>keys.split(',').some(key => (key.split('.').reduce((acc, part) => acc?.[part], item))?.toString().match(regex)));
  }
}
