import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContactComponent } from './components/contact/contact.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaqComponent } from './components/faq/faq.component';
import { LearnHowComponent } from './components/learn-how/learn-how.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { SearchPipe } from './pipes/search.pipe';
import { PageHeading2Component } from './components/page-heading2/page-heading2.component';
import { VoiceRecorderComponent } from './components/voice-recorder/voice-recorder.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SummarizedPointsComponent } from '../views/home/voice-capture/summarized-points/summarized-points.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    FaqComponent,
    LearnHowComponent,
    PageHeadingComponent,
    SearchPipe,
    PageHeading2Component,
    VoiceRecorderComponent,
    SummarizedPointsComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CarouselModule,
    ContactComponent,
    RouterModule,
    ReactiveFormsModule,
    FaqComponent,
    LearnHowComponent,
    PageHeadingComponent,
    SearchPipe,
    FormsModule,
    PageHeading2Component,
    NgbModule
  ],
})
export class SharedModule { }
