import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { DomainService } from 'src/app/core/services/domain.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  promoLink = false;
  downloadLink = false;
  aboutUsLink = false;

  constructor(private router: Router, private domainService: DomainService, ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (this.router.url.replace(/^\/+/, '') === 'home') {
        this.promoLink = false;
        this.downloadLink = false;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'home/promotions') {
        this.promoLink = true;
        this.downloadLink = false;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'home/downloads') {
        this.promoLink = false;
        this.downloadLink = true;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'about-us' || this.router.url.replace(/^\/+/, '') === 'contact-us') {
        this.aboutUsLink = true;
        this.promoLink = false;
        this.downloadLink = false;
      } else {
        this.promoLink = false;
        this.downloadLink = false;
        this.aboutUsLink = false;
      }
    });
  }

  ngOnInit(): void {
  }

  onPromotionClick() {
    this.router.navigate(['/home', 'promotions']);
  }

  onDownloadClick() {
    this.router.navigate(['/home', 'downloads']);
  }

  onAboutUsClick() {
    this.router.navigate(['/about-us']);
  }

  public scrollToElement(id: string) {
    setTimeout(() => {
      const element = document.getElementById(id) as HTMLElement;
      if (element) {
        const yOffset = -90;
        const y = element!.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 0);
  }


  public copyToClipboard() {
    this.domainService.copyToClipboard('contact@zaper.ai');
  }

}
