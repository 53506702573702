import { Component, Input, OnInit } from '@angular/core';
import { Faq } from 'src/app/core/models/data.model';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() type: string = "";
  @Input() hasTitle: boolean = true;
  public faqs: Faq[] = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getFaq().subscribe((faq: any) => this.faqs = faq[this.type]);
  }
}
