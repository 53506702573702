<footer>
  <div class="zaper-ai container">
    <div class="zaper-footer">
      <div class="logo-container">
        <div class="logo">
          <img src="assets/images/logo.png" alt="logo.png">
          <h1>ZAPER AI</h1>
        </div>
        <a href="javascript:void(0)" routerLink="/contact" [queryParams]="{'type':'Demo schedule'}"class="zaper-btn zaper-btn-light">
          <span class="btn-icon"></span>
          <span>BOOK DEMO</span>
        </a>
      </div>
      <div class="section">
        <p>Company</p>
        <ul>
          <li>
            <a href="javascript:void(0);" routerLink="/company">About</a>
          </li>
          <li><a href="javascript:void(0);"  routerLink="/company">Our Products</a></li>
          <li> <a href="javascript:void(0);"  routerLink="/company">Our Team</a>
          </li>
        </ul>
      </div>
      <div class="section">
        <p>Legal & Partners</p>
        <ul>
          <li>
            <a href="javascript:void(0)" [routerLink]="['/contact']"
              [queryParams]="{'type':'Reseller Interest'}">Sales Partner</a>
          </li>
          <li><a href="javascript:void(0)" routerLink="/faq">FAQ</a></li>
          <!-- <li><a>FAQs</a></li> -->
          <li><a href="javascript:void(0);" routerLink="legal/terms-and-conditions">Terms & Conditions</a></li>
          <li><a href="javascript:void(0);" routerLink="legal/privacy-policy">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="section">
        <p>Send Us a Shout!</p>
        <ul>
          <li>
            <a href="javascript:void(0)" routerLink="/contact" [queryParams]="{'type':'Demo schedule'}">Get a Demo</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/zaper-ai/" target="_blank">LinkedIn</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="copyToClipboard()" id="zaper-email">Email</a>
          </li>
          <li>
            <a href="https://x.com/ZaperAI?s=08">X / Twitter</a>
          </li>
        </ul>
      </div>

    </div>
    <div class="zaper-footer-break d-none d-md-block"></div>
    <div class="zaper-footer-bottom">
      <img src="assets/images/logo-lg.png" alt="logo.png">
      <div class="zaper-footer-break d-none d-md-block"></div>
      <p>Copyright &copy; 2024 <strong>Zaper.Inc.</strong> All rights reserved.</p>
    </div>
  </div>
</footer>
