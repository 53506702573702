<section class="zaper-ai container" [ngClass]="{'no-title':!hasTitle}">
  <hr *ngIf="hasTitle">
  <div class="faq">
    <div class="left-sec" *ngIf="hasTitle">
      <h5>Frequently
        Asked
        Questions!</h5>
    </div>
    <div class="right-sec">
      <div class="heading">
        <h1>Discovery Desk</h1>
      </div>
      <div class="questions-list">
        <div class="question-item" *ngFor="let faq of faqs;">
          <div class="question-number">/ {{faq.sequence}}</div>
          <div class="question-header" onclick="this.parentElement.classList.toggle('active')">
            <div class="question-content">
              <div class="question-text">{{faq.question}}</div>
            </div>
            <button class="toggle-button" aria-label="Toggle answer"></button>
          </div>
          <div class="question-answer">
            {{faq.answer}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
