import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Task } from 'src/app/core/models/domain.model';

@Component({
  selector: 'app-summarized-points',
  templateUrl: './summarized-points.component.html',
  styleUrls: ['./summarized-points.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummarizedPointsComponent {
  @Input() isLoading: boolean = false;
  @Input() tasks: Task[] = [];
  @Output() onAction$: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
  }

  public onAction(retry: boolean) {
    this.onAction$.emit(retry);
  }

}
