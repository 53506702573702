import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-heading2',
  templateUrl: './page-heading2.component.html',
  styleUrls: ['./page-heading2.component.scss']
})
export class PageHeading2Component {

  @Input() routerLink: string = "/";
  constructor(private router: Router) { }

  public goBack() {
    if (this.routerLink) {
      this.router.navigate([this.routerLink]);
    } else {
      history.back();
    }
  }
}
