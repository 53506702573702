import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @ViewChild('menuButton', { static: false }) menuButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('closeButton', { static: false }) closeButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('mobileMenu', { static: false }) mobileMenu!: ElementRef<HTMLDivElement>;
  @ViewChild('menuItem', { static: false }) menuItem!: ElementRef<HTMLDivElement>;

  public isMobile: boolean = false;
  public activeId: number = 1;

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.setFlagsBasedOnUrl();
    this.router.events.subscribe(() => {
      this.setFlagsBasedOnUrl();
    });
  }

  setFlagsBasedOnUrl() {
    const currentUrl = this.router.url;
    this.activeId = currentUrl === '/company' ? 1 : currentUrl === '/security' ? 2 : currentUrl === '/careers' ? 3 : 0;
  }

  toggleMenu(isOpen: boolean): void {
    if (this.mobileMenu) {
      if (isOpen) {
        this.renderer.addClass(this.mobileMenu.nativeElement, 'active');
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
      } else {
        this.renderer.removeClass(this.mobileMenu.nativeElement, 'active');
        this.renderer.removeStyle(document.body, 'overflow');
      }
      this.menuButton.nativeElement.setAttribute('aria-expanded', isOpen.toString());
      this.mobileMenu.nativeElement.setAttribute('aria-hidden', (!isOpen).toString());
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.menuButton.nativeElement, 'click', () => this.toggleMenu(true));
    this.renderer.listen(this.closeButton.nativeElement, 'click', () => this.toggleMenu(false));
    this.renderer.listen(this.menuItem.nativeElement, 'click', () => this.toggleMenu(false));
  }
}
