<section class="zaper-ai">
  <div class="page-heading">
    <a href="javascript:void(0)" (click)="goBack()"class="zaper-back-btn">
      <span class="btn-icon"></span>
      <span>Back</span>
    </a>
    <div class="d-flex">
      <img src="assets/images/logo.png" class="d-none d-md-block" alt="logo.png">
      <img src="assets/images/logo-sm.png" class="d-md-none" alt="logo.png">
      <h2 class="title">ZAPER AI</h2>
    </div>
  </div>
</section>
