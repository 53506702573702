<section class="recording-container">
  <img class="rec-bg d-none d-md-block" src="assets/images/transparent-bg-lg.png" alt="transparent-bg">
  <img class="rec-bg d-md-none" src="assets/images/transparent-bg-sm.png" alt="transparent-bg">

  <div class="recorder" [hidden]="summarized" >
    <div class="wave-form" #mic></div>
    <p #progress>00:00</p>
    <button type="button" (click)="stopRecording()"></button>
  </div>
  <app-summarized-points *ngIf="summarized" [tasks]="tasks" [isLoading]="isLoading"(onAction$)="onAction($event)"></app-summarized-points>
</section>
