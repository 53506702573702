<div class="zaper-ai zaper-header">
  <div class="container">
    <header role="banner">
      <nav class="navbar" role="navigation" aria-label="Main navigation">
        <div class="logo-container">
          <a href="javascript:void(0)" routerLink="/" class="logo" aria-label="Zaper AI Home">
           <img src="assets/images/logo-black.png" alt="logo.png"> <span aria-hidden="true">ZAPER AI</span>
          </a>
          <span class="logo-underline" aria-hidden="true"></span>
        </div>

        <ul class="nav-links" role="menubar">
          <li role="none"><a href="javascript:void(0)" routerLink="/company" role="menuitem"
              [ngClass]="{'active-item':activeId==1}">Company</a></li>
          <li role="none"><a href="javascript:void(0)" routerLink="/security" role="menuitem"
              [ngClass]="{'active-item':activeId==2}">Security</a>
          </li>
          <li role="none"><a href="javascript:void(0)" routerLink="/careers" role="menuitem"
              [ngClass]="{'active-item':activeId==3}">Careers</a></li>
        </ul>

        <a href="javascript:void(0)" routerLink="/contact" [queryParams]="{'type':'Demo schedule'}"
          class="zaper-btn book-demo" role="button" aria-label="Book a Demo">
          <span class="btn-icon"></span>
          <span>BOOK DEMO</span>
        </a>

        <button #menuButton class="menu-button" aria-label="Toggle mobile menu" aria-expanded="false"
          aria-controls="mobile-menu">
          <img src="assets/images/icons/toggle.png" alt="toggle.png">
        </button>
      </nav>
    </header>

    <div #mobileMenu id="mobile-menu" class="mobile-menu" role="dialog" aria-modal="true" aria-hidden="true">
      <div class="mobile-menu-header">
        <div class="logo-container">
          <a href="javascript:void(0)" routerLink="/company" class="logo" aria-label="Zaper AI Home">
            <span aria-hidden="true">ZAPER.AI</span>
          </a>
          <span class="logo-underline" aria-hidden="true"></span>
        </div>
        <button #closeButton class="close-button" aria-label="Close mobile menu">✕</button>
      </div>

      <nav aria-label="Mobile navigation">
        <ul class="mobile-nav-links" role="menu" #menuItem>
          <li role="none">
            <a href="javascript:void(0)" routerLink="" role="menuitem">
              Home
              <img src="assets/images/icons/ar-light-right.svg" alt="arrow.png">
            </a>
          </li>
          <li role="none">
            <a href="javascript:void(0)" routerLink="/company" role="menuitem">
              Company
              <img src="assets/images/icons/ar-light-right.svg" alt="arrow.png">
            </a>
          </li>
          <li role="none">
            <a href="javascript:void(0)" routerLink="/security" role="menuitem">
              Security
              <img src="assets/images/icons/ar-light-right.svg" alt="arrow.png">
            </a>
          </li>
          <li role="none">
            <a href="javascript:void(0)" routerLink="/careers" role="menuitem">
              Careers
              <img src="assets/images/icons/ar-light-right.svg" alt="arrow.png">
            </a>
          </li>
        </ul>
      </nav>

      <a #closeButton href="javascript:void(0)" routerLink="/contact" [queryParams]="{'type':'Demo schedule'}"
        class="zaper-btn book-demo" role="button" aria-label="Book a Demo">
        <img src="assets/images/icons/ar-dark-right.svg" alt="arrow.png">
        <span>BOOK DEMO</span>
      </a>
    </div>
  </div>
</div>
