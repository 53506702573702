<section class="zaper-ai container">
  <div class="learn-how">
    <div class="row">
      <div class="col-lg-9 left-sec">
        <h3>Learn how Zaper AI can help your firm</h3>
        <p>Book a demo to get started</p>
      </div>
      <div class="col-lg-3 right-sec">
        <a routerLink="/contact" [queryParams]="{'type':'Demo schedule'}" class="zaper-btn">
          <span class="btn-icon"></span>
          <span>BOOK DEMO</span>
        </a>
      </div>
    </div>
  </div>
</section>
