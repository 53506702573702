import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'zaper-ai-web';
  public isLoading = true;
  public isHidden: boolean = false;
  private excludedUrls: string[] = [
    "/careers/AI Python Developer",
    '/careers/Full Stack Developer',
    '/careers/Senior DevOps Engineer',
    '/careers/Junior Python Engineer (2 Positions)',
    '/careers/Frontend Engineer',
    '/careers/AI/ML Research Scientist',
    '/careers/Data Engineer',
    '/careers/UX/UI Designer',
    '/careers/Product Manager - AI Solutions',
    '/careers/Quality Assurance Engineer',
    '/legal/privacy-policy',
    '/legal/terms-and-conditions',
    '/contact',
    '/faq'
  ];

  constructor(private renderer: Renderer2, private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    const script = this.renderer.createElement('script');
    script.src = 'assets/js/script.js';
    script.type = 'text/javascript';
    this.renderer.appendChild(document.body, script);

    this.router.events.subscribe(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      this.isHidden = this.excludedUrls.includes(decodeURIComponent(this.router.url?.split('?')[0]));
    });
  }
}
